import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import emailjs from 'emailjs-com';

const serviceID = "service_v6sxqoo";
const templateID = "template_cbpsysr";
const userID = "DiEk9hdEsP4baIN3-";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  var [phone, setPhone] = useState("");
  var templateParams = {
    telephone: phone,
    };

    if (phone.length == 10) {
      emailjs.send(serviceID, templateID, templateParams, userID);
  }
         
         
   
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  
  


  
  
      return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
               On vous rappelle
              </h3>
          </div>
      
          <div className="cta-action">
{phone.length < 10 ? ( 
            <Input className = "input1" id="phone" type="tel" name="phone" 
            
            onChange={(e) => setPhone(e.target.value)} 
                
            label="Subscribe" className='label1' labelHidden hasIcon="right" placeholder="Votre numéro de téléphone ?"
            value={phone.replace(/[^\d]/g, '')}  >
             
            
  

            </Input>
)
:

"Merci"


}
                    </div>
        </div>
      </div>

    

    </section>



    

    
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;



export default Cta;

